<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    :clickToClose="dismissable"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('loginRegister.register') }}
      </div>
      <register formType="modal" :dismissable="dismissable" />
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import Register from '@/components/auth/register';
export default {
  components: {
    Register,
  },
  data: () => ({
    modalName: 'modal-register',
    dismissable: true,
  }),
  methods: {
    async beforeOpen(event) {
      if (event.params && event.params.dismissable !== null) {
        this.dismissable = event.params.dismissable;
      }
    },
    close() {
      if (this.dismissable) {
        this.$modal.hide(this.modalName);
      } else {
        if (window.history.length > 1) {
          this.$router.back();
        } else {
          this.$router.replace('/');
        }
      }
    },
  },
};
</script>
